import React from 'react';
import { Redirect } from 'react-router-dom';
import _get from 'lodash/get';

const currentUserRole = _get(window, 'cccisd.fortress.user.acting.data_type') || '';

export default class Component extends React.Component {
    render() {
        if (currentUserRole === 'uberadmin') {
            return <Redirect to="/dashboard" />;
        }
        if (currentUserRole === 'orgAdmin') {
            return <Redirect to="/registration" />;
        }
        if (currentUserRole === 'clubContact') {
            return <Redirect to="/admin" />;
        }

        return <Redirect to="/" />;
    }
}
